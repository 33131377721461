import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Container,
} from '@mui/material';

const Login = ({ onLogin, passwordError }) => {
  const [password, setPassword] = useState('');

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = () => {
    if (password) {
      onLogin(password);
    }
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Dialog open fullWidth>
        <DialogTitle>Login</DialogTitle>
        <DialogContent>
          <Typography>Please enter the password to proceed:</Typography>
          <TextField
            autoFocus
            margin="dense"
            label="Password"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            error={!!passwordError}
            helperText={passwordError}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleLogin} color="primary" variant="contained">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Login;
