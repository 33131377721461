import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

axios.defaults.withCredentials = true;

export const getUserId = () => {
  let userId = localStorage.getItem('userId');
  if (!userId) {
    userId = uuidv4();
    localStorage.setItem('userId', userId);
  }
  return userId;
};

export const setPassword = (password) => {
  localStorage.setItem('password', password);
};

const getAuthHeaders = () => {
  const userId = getUserId();
  const password = localStorage.getItem('password');
  return {
    'User-Id': userId,
    'Authorization': `Bearer ${password}`,
  };
};

const apiUrl = process.env.REACT_APP_API_BASE_URL;

export const bullxConvert = (text) => {
  const lines = text.split('\n').filter(line => line.trim() !== '');
  return lines.map(line => {
    const [publicKey, privateKey] = line.split(':').map(part => part.trim());
    return { publicKey, privateKey };
  });
};

export const calculateTotalBalances = (wallets) => {
  let totalSol = 0;
  const tokenBalances = {};
  let totalValueInSol = 0;

  for (const wallet of wallets) {
    totalSol += wallet.sol_balance;

    for (const token of wallet.tokens) {
      if (!tokenBalances[token.mint]) {
        tokenBalances[token.mint] = 0;
      }
      tokenBalances[token.mint] += token.amount;

      totalValueInSol += token.amount; // We assume 1:1 value for simplicity
    }
  }

  return { sol: totalSol, tokens: tokenBalances, totalValueInSol };
};

export const fetchWallets = async () => {
  const headers = getAuthHeaders();
  const response = await axios.get(`${apiUrl}/api/wallets`, {
    headers,
  });
  return response.data;
};

export const uploadWallets = async (wallets) => {
  const headers = getAuthHeaders();
  const response = await axios.post(`${apiUrl}/api/wallets`, wallets, {
    headers,
  });
  return response.data;
};

export const removeWallets = async () => {
  const headers = getAuthHeaders();
  await axios.delete(`${apiUrl}/api/wallets`, {
    headers,
  });
};

export const clearWallets = async (selectedWallets) => {
  const headers = getAuthHeaders();
  const response = await axios.post(
    `${apiUrl}/api/clear`,
    selectedWallets,
    {
      headers,
      withCredentials: true,
    }
  );
  return response.data;
};

export const heatWallets = async (selectedWallets, heatTime) => {
  const headers = getAuthHeaders();
  const response = await axios.post(
    `${apiUrl}/api/heat`,
    {
      wallets: selectedWallets,
      heatTime
    },
    {
      headers,
      withCredentials: true,
    }
  );
  return response.data;
};

export const fetchWalletBalances = async () => {
  const headers = getAuthHeaders();
  const response = await axios.get(`${apiUrl}/api/balances`, {
    headers,
  });
  return response.data;
};

export const purchaseTokens = async (selectedWallets, targetToken, buyAmount, randomAmount, slippage, fee, delayBetween) => {
  const headers = getAuthHeaders();
  const response = await axios.post(
    `${apiUrl}/api/purchase`,
    {
      wallets: selectedWallets,
      target: targetToken,
      buyAmount: parseFloat(buyAmount),
      randomAmount: parseFloat(randomAmount),
      slippage: parseFloat(slippage),
      fee: parseFloat(fee),
      delayBetween: parseInt(delayBetween)
    }, {
      headers,
      withCredentials: true
    });
  return response.data;
};

export const sendSOL = async (selectedWallets, receiver) => {
  const headers = getAuthHeaders();
  const response = await axios.post(
    `${apiUrl}/api/receive`,
    {
      wallets: selectedWallets,
      receiver: receiver
    },
    {
      headers,
      withCredentials: true
    }
  );
  return response.data;
};

export const truncateAddress = (address) => {
  if (!address || address.length <= 10) return address || '';
  return `${address.slice(0, 5)}...${address.slice(-5)}`;
};
