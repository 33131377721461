import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
  createTheme,
  ThemeProvider,
  CssBaseline,
  Button,
  Typography,
  Container,
  Box,
  Alert,
  TextField,
  Collapse,
  Grid,
  Checkbox,
  ButtonGroup,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Brightness4, Brightness7, ExpandMore, ExpandLess, Delete as DeleteIcon } from '@mui/icons-material';
import AssistWalkerIcon from '@mui/icons-material/AssistWalker';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import RefreshIcon from '@mui/icons-material/Refresh';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SendIcon from '@mui/icons-material/Send';
import {
  bullxConvert,
  calculateTotalBalances,
  fetchWallets,
  uploadWallets,
  removeWallets,
  clearWallets,
  heatWallets,
  fetchWalletBalances,
  getUserId,
  truncateAddress,
  setPassword,
  purchaseTokens,
  sendSOL
} from './walletService';
import WalletCard from './WalletCard';
import Login from './Login';

axios.defaults.withCredentials = true;

const App = () => {
  const [wallets, setWallets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [darkMode, setDarkMode] = useState(true);
  const [statusMessage, setStatusMessage] = useState('');
  const [hideZeroBalances, setHideZeroBalances] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [targetToken, setTargetToken] = useState('');
  const [buyAmount, setBuyAmount] = useState(0.8);
  const [randomAmount, setRandomAmount] = useState(20);
  const [slippage, setSlippage] = useState(100);
  const [fee, setFee] = useState(0.01);
  const [delayBetween, setDelayBetween] = useState(0);
  const [totalBalances, setTotalBalances] = useState({ sol: 0, tokens: {}, totalValueInSol: 0 });
  const [balancesOpen, setBalancesOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [confirmClearOpen, setConfirmClearOpen] = useState(false);
  const [confirmSendOpen, setConfirmSendOpen] = useState(false);
  const [receiverWallet, setReceiverWallet] = useState('');
  const [sendInProgress, setSendInProgress] = useState(false);
  const [purchaseInProgress, setPurchaseInProgress] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  
  // New state to hold the calculated value
  const [calculatedValue, setCalculatedValue] = useState(0);

  useEffect(() => {
    getUserId();
  }, []);

  const handleFetchWallets = useCallback(async () => {
    setStatusMessage('');
    setLoading(true);
    try {
      const wallets = await fetchWallets();
      setWallets(wallets);
      const updatedBalances = await fetchWalletBalances();
      setWallets(updatedBalances);
      setTotalBalances(calculateTotalBalances(updatedBalances));
    } catch (error) {
      console.error('Error fetching wallet data:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleFileUpload = async (event) => {
    setStatusMessage('');
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const text = e.target.result;
        const parsedWallets = bullxConvert(text);
        try {
          const wallets = await uploadWallets(parsedWallets);
          setWallets(wallets);
          const updatedBalances = await fetchWalletBalances();
          setWallets(updatedBalances);
          setTotalBalances(calculateTotalBalances(updatedBalances));
        } catch (error) {
          console.error('Error uploading wallets:', error);
          setStatusMessage('Error uploading wallets');
        }
      };
      reader.readAsText(file);
    }
  };

  const handleRemoveWallets = async () => {
    setStatusMessage('');
    try {
      await removeWallets();
      setWallets([]);
      setTotalBalances({ sol: 0, tokens: {}, totalValueInSol: 0 });
      setStatusMessage('Wallets removed successfully');
      setSelectedWallets([]); // Reset selected wallets
    } catch (error) {
      console.error('Error removing wallets:', error);
      setStatusMessage('Error removing wallets');
    }
  };

  const handleClearWallets = async () => {
    setConfirmClearOpen(false); // Close the dialog immediately
    setStatusMessage('');
    try {
      setLoading(true);
      await clearWallets(selectedWallets);
      await handleFetchWallets();
      setStatusMessage('Wallets cleared successfully');
      setSelectedWallets([]); // Reset selected wallets
    } catch (error) {
      console.error('Error clearing wallets:', error);
      setStatusMessage('Error clearing wallets');
    } finally {
      setLoading(false);
    }
  };

  const handleHeatWallets = async (heatTime) => {
    setStatusMessage('');
    try {
      setLoading(true);
      await heatWallets(selectedWallets, heatTime);
      await handleFetchWallets();
      setStatusMessage('Wallets heated successfully');
      setSelectedWallets([]); // Reset selected wallets
    } catch (error) {
      console.error('Error heating wallets:', error);
      setStatusMessage('Some mysterious shit happened. Try again. If error occurs again please contact developer');
    } finally {
      setLoading(false);
    }
  };

  const handleBuyTokens = async () => {
    setStatusMessage('');
    if (!targetToken) {
      setStatusMessage('Target token is required');
      return;
    }
    setStatusMessage('');
    setPurchaseInProgress(true);
    try {
      await purchaseTokens(selectedWallets, targetToken, buyAmount, randomAmount, slippage, fee, delayBetween);
      setStatusMessage('Purchase successful');
      await handleFetchWallets();
    } catch (error) {
      console.error('Error purchasing tokens:', error);
      setStatusMessage('Error purchasing tokens');
    } finally {
      setPurchaseInProgress(false);
    }
  };

  const handleSendSOL = async () => {
    setConfirmSendOpen(false); // Close the dialog immediately
    setStatusMessage('');
    if (!receiverWallet) {
      setStatusMessage('Receiver wallet is required');
      return;
    }
    setStatusMessage('');
    setSendInProgress(true);
    try {
      await sendSOL(selectedWallets, receiverWallet);
      setStatusMessage('SOL sent successfully');
      await handleFetchWallets();
    } catch (error) {
      console.error('Error sending SOL:', error);
      setStatusMessage('Error sending SOL');
    } finally {
      setSendInProgress(false);
    }
  };

  useEffect(() => {
    handleFetchWallets();
  }, [handleFetchWallets]);

  const handleSort = () => {
    const newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
    setSortDirection(newDirection);
    const sortedWallets = [...wallets].map(wallet => ({
      ...wallet,
      tokens: wallet.tokens ? wallet.tokens.sort((a, b) => {
        if (newDirection === 'asc') return a.amount - b.amount;
        return b.amount - a.amount;
      }) : []
    }));
    setWallets(sortedWallets);
  };

  const handleWalletSelection = (publicAddress) => {
    setSelectedWallets(prevSelectedWallets => {
      if (prevSelectedWallets.includes(publicAddress)) {
        return prevSelectedWallets.filter(address => address !== publicAddress);
      } else {
        return [...prevSelectedWallets, publicAddress];
      }
    });
  };

  const handleSelectAll = () => {
    if (selectedWallets.length === wallets.length) {
      setSelectedWallets([]);
    } else {
      setSelectedWallets(wallets.map(wallet => wallet.public_address));
    }
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
    },
    spacing: 8,
  });

  const handleLogin = (password) => {
    setPassword(password);
    fetchWallets()
      .then(() => {
        setIsAuthenticated(true);
        handleFetchWallets();
      })
      .catch(() => {
        setPasswordError('Invalid password');
      });
  };

  // Function to calculate the value based on the formula
  const calculateValue = useCallback(() => {
    const randomPart = (buyAmount * (randomAmount / 100)) + buyAmount;
    const slippagePart = (randomPart * (slippage / 100));
    const total = randomPart + slippagePart + fee;
    setCalculatedValue(total);
  }, [buyAmount, randomAmount, slippage, fee]);

  // Use effect to recalculate whenever the dependent variables change
  useEffect(() => {
    calculateValue();
  }, [buyAmount, randomAmount, slippage, fee, calculateValue]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {isAuthenticated ? (
        <Container maxWidth={false} sx={{ my: 4 }}>
          <Box display={"flex"} alignItems={"center"} flexWrap={"wrap"} gap={2} style={{ marginBottom: '20px' }}>
            <Typography variant="h4" gutterBottom style={{ marginBottom: '0' }}>
              <img
                src={`/logo.png`} alt="logo"
                style={{ width: '40px', height: '40px', verticalAlign: 'middle', marginRight: '5px' }}
                loading="lazy"
              />
              <span style={{ fontWeight: 700, marginRight: '20px' }}> Jeet Maxxing </span>
            </Typography>
            <ButtonGroup size="small" color="secondary" aria-label="Small button group" style={{ height: '36.5px' }}>
              <Button
                variant="outlined"
                component="label"
                startIcon={<AccountBalanceWalletIcon />}
              >
                Load Wallets
                <input
                  type="file"
                  hidden
                  onChange={handleFileUpload}
                />
              </Button>
              <Button
                variant="outlined"
                color="error"
                onClick={handleRemoveWallets}
                disabled={loading}
                startIcon={<DeleteIcon />}
              >
                {loading ? 'Removing...' : 'Remove Wallets'}
              </Button>
            </ButtonGroup>
            {statusMessage && (
              <Alert severity={statusMessage.startsWith('Error') ? 'error' : 'success'} style={{ maxHeight: "36.5px", paddingTop: "0px", paddingBottom: "0px"}}>
                {statusMessage}
              </Alert>
            )}

            <IconButton sx={{ display: 'none' }}
              color="inherit"
              onClick={() => setDarkMode(!darkMode)}
              style={{ float: 'right', marginLeft: "auto" }}
            >
              {darkMode ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
          </Box>
          <Box display="flex" alignItems="flex-start" flexWrap={"wrap"} gap={2} style={{ marginBottom: '20px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleFetchWallets}
              disabled={loading}
              startIcon={<RefreshIcon />}
            >
              {loading ? 'Refreshing...' : 'Refresh'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleHeatWallets(3000)}
              disabled={loading || selectedWallets.length === 0}
              startIcon={<DirectionsRunIcon />}
            >
              {loading ? 'Heating...' : 'Fast Heat'}
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => handleHeatWallets(300000)}
              disabled={loading || selectedWallets.length === 0}
              startIcon={<AssistWalkerIcon />}
            >
              {loading ? 'Heating...' : 'Slow Heat'}
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={() => setConfirmClearOpen(true)}
              disabled={loading || selectedWallets.length === 0}
              startIcon={<CleaningServicesIcon />}
            >
              {loading ? 'Clearing...' : 'Clear Wallets'}
            </Button>
          </Box>
          <Box display="flex" alignItems="flex-start" mb={3} gap={4}>
            <Box display="flex" flexDirection="column" alignItems="flex-start" flexWrap={"wrap"} gap={2}>
              <Box display="flex" alignItems="center" flexWrap={"wrap"} gap={2}>
                <TextField
                  label="Target Token"
                  variant="outlined"
                  value={targetToken}
                  onChange={(e) => setTargetToken(e.target.value)}
                  size="small"
                  style={{ minWidth: '200px' }}
                />
                <TextField
                  label="Amount (SOL)"
                  variant="outlined"
                  value={buyAmount}
                  onChange={(e) => setBuyAmount(parseFloat(e.target.value))}
                  size="small"
                  type="number"
                  style={{ maxWidth: '120px' }}
                />
                <TextField
                  label="Random (%)"
                  variant="outlined"
                  value={randomAmount}
                  onChange={(e) => setRandomAmount(parseFloat(e.target.value))}
                  size="small"
                  type="number"
                  style={{ maxWidth: '100px' }}
                />
                <TextField
                  label="Slippage (%)"
                  variant="outlined"
                  value={slippage}
                  onChange={(e) => setSlippage(parseFloat(e.target.value))}
                  size="small"
                  type="number"
                  style={{ maxWidth: '100px' }}
                />
                <TextField
                  label="Fee (SOL)"
                  variant="outlined"
                  value={fee}
                  onChange={(e) => setFee(parseFloat(e.target.value))}
                  size="small"
                  type="number"
                  style={{ maxWidth: '100px' }}
                />
                <TextField
                  label="Delay (s)"
                  variant="outlined"
                  value={delayBetween}
                  onChange={(e) => setDelayBetween(parseInt(e.target.value))}
                  size="small"
                  type="number"
                  style={{ maxWidth: '100px' }}
                />
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleBuyTokens}
                  disabled={loading || selectedWallets.length === 0 || purchaseInProgress}
                  startIcon={<AttachMoneyIcon />}
                >
                  {purchaseInProgress ? 'Purchasing...' : 'Purchase Tokens'}
                </Button>
                <Typography variant="h6" fontSize="14px">{'>' + calculatedValue.toFixed(2)}</Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" flexWrap={"wrap"} gap={2}>
            <Button
              variant="outlined"
              onClick={handleSelectAll}
              disabled={loading}
            >
              {selectedWallets.length === wallets.length ? 'Deselect All' : 'Select All'}
            </Button>
            <Button onClick={() => setBalancesOpen(!balancesOpen)} variant="outlined">
              {balancesOpen ? <ExpandLess /> : <ExpandMore />} Total Balances
            </Button>
            <Box display="flex" alignItems="center" gap={2} style={{ marginRight: '20px' }}>
              <Checkbox className='checkbox'
                checked={hideZeroBalances}
                onChange={() => setHideZeroBalances(!hideZeroBalances)}
              />
              Hide Zero Balances
            </Box>

            <Box display="flex" alignItems="center" flexWrap={"wrap"} gap={2}>
              <TextField
                label="Receiver Wallet"
                variant="outlined"
                value={receiverWallet}
                onChange={(e) => setReceiverWallet(e.target.value)}
                size="small"
                style={{ minWidth: '300px' }}
              />
              <Button
                variant="contained"
                onClick={() => setConfirmSendOpen(true)}
                disabled={loading || selectedWallets.length === 0 || sendInProgress}
                startIcon={<SendIcon />}
              >
                {sendInProgress ? 'Sending...' : 'Send SOL'}
              </Button>
            </Box>
          </Box>
          <Collapse in={balancesOpen}>
            <Box mt={3}>
              <Typography variant="h6" fontSize="20px">Total SOL Balance: {totalBalances.sol.toFixed(2)}</Typography>
              {Object.keys(totalBalances.tokens)
                .filter(mint => totalBalances.tokens[mint] > 0)
                .map((mint, index) => (
                  <Typography key={index} variant="h6">
                    {truncateAddress(mint)}: {totalBalances.tokens[mint].toFixed(2)}
                  </Typography>
                ))}
            </Box>
          </Collapse>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            {wallets.map((wallet, index) => (
              <Grid item xs={12} sm={6} md={3} lg={3} key={index}>
                <WalletCard
                  wallet={wallet}
                  selectedWallets={selectedWallets}
                  handleWalletSelection={handleWalletSelection}
                  sortDirection={sortDirection}
                  handleSort={handleSort}
                  hideZeroBalances={hideZeroBalances}
                />
              </Grid>
            ))}
          </Grid>
          <Dialog
            open={confirmClearOpen}
            onClose={() => setConfirmClearOpen(false)}
            aria-labelledby="confirm-clear-dialog"
          >
            <DialogTitle id="confirm-clear-dialog">Confirm Clear Wallets</DialogTitle>
            <DialogContent>Are you sure you want to clear the selected wallets?</DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmClearOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleClearWallets} color="secondary" startIcon={<CleaningServicesIcon />}>
                Clear Wallets
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={confirmSendOpen}
            onClose={() => setConfirmSendOpen(false)}
            aria-labelledby="confirm-send-dialog"
          >
            <DialogTitle id="confirm-send-dialog">Confirm Send SOL</DialogTitle>
            <DialogContent>Are you sure you want to send SOL to the receiver wallet?</DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmSendOpen(false)} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSendSOL} color="secondary" startIcon={<SendIcon />}>
                Send SOL
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      ) : (
        <Login onLogin={handleLogin} passwordError={passwordError} />
      )}
    </ThemeProvider>
  );
};

export default App;
