import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableSortLabel,
  ButtonGroup,
  Button,
  Box,
  IconButton
} from '@mui/material';
import { truncateAddress } from './walletService';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const WalletCard = ({ wallet, selectedWallets, handleWalletSelection, sortDirection, handleSort, hideZeroBalances }) => {
  const handleCopyAddress = (address) => {
    navigator.clipboard.writeText(address)
      .catch((error) => {
        console.error('Error copying address: ', error);
      });
  };

  return (
    <Card style={{ backgroundColor: selectedWallets.includes(wallet.public_address) ? '#234052' : 'inherit' }}>
      <CardContent>
        <Box display="flex" alignItems="center">
          <Checkbox
            className='checkbox'
            checked={selectedWallets.includes(wallet.public_address)}
            onChange={() => handleWalletSelection(wallet.public_address)}
          />
          <Typography variant="h6" style={{ marginLeft: '8px' }}>{truncateAddress(wallet.public_address)}</Typography>
          <Typography variant="h6">
            <IconButton onClick={() => handleCopyAddress(wallet.public_address)} size="small">
              <ContentCopyIcon />
            </IconButton>
          </Typography>
        </Box>
        <Typography variant="body1">SOL: {wallet.sol_balance}</Typography>
        <TableContainer component={Paper} style={{ maxHeight: 200 }} sx={{ my: 2 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ p: 1 }}>Mint</TableCell>
                <TableCell sx={{ p: 1 }} align="right">
                  <TableSortLabel
                    active
                    direction={sortDirection}
                    onClick={handleSort}
                  >
                    Balance
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {wallet.tokens
                .filter(token => !hideZeroBalances || token.amount > 0)
                .map((token, tokenIndex) => (
                  <TableRow key={tokenIndex}>
                    <TableCell sx={{ p: 1 }}>
                      <a className='mintLink' href={`https://pump.fun/${token.mint}`} target="_blank" rel="noopener noreferrer">
                        {truncateAddress(token.mint)}
                      </a>
                    </TableCell>
                    <TableCell sx={{ p: 1 }} align="right">{token.amount}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <ButtonGroup size="small" color="secondary" aria-label="Small button group" style={{ marginTop: '10px' }}>
          <Button
            href={`https://solscan.io/account/${wallet.public_address}`}
            target="_blank"
            variant="outlined"
          >
            View on Solscan
          </Button>
          <Button
            href={`https://pump.fun/profile/${wallet.public_address}`}
            target="_blank"
            variant="outlined"
          >
            View on Pump
          </Button>
        </ButtonGroup>
      </CardContent>
    </Card>
  );
};

export default WalletCard;
